// @flow
import { graphql } from "gatsby";
// @flow
import React from "react";
import styled from "styled-components";
import Carousel from "../components/Carousel";
import FacCard from "../components/FacCard";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import LearnMore from "../components/LearnMore";
import Page from "../components/Page";
import Section from "../components/Section";
import { query, MEDIUM_BREAKPOINT } from "../style";

import type { ImageSharp, Facility, ImageType } from "../types";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    office: Facility,
    pt: Facility,
    mri: Facility,
    xray: Facility,
    injection: Facility
  }
};

export default class Index extends React.Component<Props> {
  render() {
    const {
      mainImage,
      secondImage,
      thirdImage,
      office,
      pt,
      mri,
      xray,
      injection
    } = this.props.data;
    return (
      <Page title="Our Facilities">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Explore the facilities"
          subtitle="We designed and built our facilities to be convenient, comfortable and efficient. No need to travel across town for MRIs, procedures, or different treatments. From state-of-the-art diagnostic imaging to physical therapy and our medical clinics, we can take care of your spine related needs."
          cta="Make an appointment"
        />
        <TopTeamSection color="#EEF2F7">
          <TeamHeader>
            <TeamTitle>The Meridian Campus</TeamTitle>
            <TeamCopy>
              Our goal is to make you comfortable and confident while getting
              you the right treatment. Our offices are conveniently located off
              of I-84 and Eagle road, right next to St. Luke's Meridian, and
              feature everything we need to diagnose and treat your condition.
              Surgical treatments are performed right next door at the hospital.
              In addition to our imaging lab, physical therapy center and
              injection suite, we also feature comfortable spaces for you to
              speak with your doctor individually.
            </TeamCopy>
          </TeamHeader>
          <Carousel height={1}>
            {office.images.map(im => {
              return <FacCard key={im.resolutions.src} image={im} />;
            })}
          </Carousel>
        </TopTeamSection>

        <TeamSection color="#E3E0F3">
          <TeamHeader>
            <TeamTitle>Diagnostic Services</TeamTitle>
            <TeamCopy>
              A correct diagnosis is the most critical part of your treatment,
              so we built the Diagnostic Imaging Lab located in our main office
              to give our doctors everything they need to make an accurate
              assessment. Our Open MRI is non-claustrophobic and can resolve
              icredible detail. It is also the only Open MRI in the Treasure
              Valley. Rouding out our diagnostic imaging machines is a Planar
              X-Ray, and a brand new Flouroscopy machine.
            </TeamCopy>
          </TeamHeader>
          <Carousel height={1}>
            {mri.images
              .map(im => <FacCard key={im.resolutions.src} image={im} />)
              .concat(
                xray.images.map(im => (
                  <FacCard key={im.resolutions.src} image={im} />
                ))
              )}
          </Carousel>
        </TeamSection>

        <TeamSection color="#D3EBFF">
          <TeamHeader>
            <TeamTitle>Full-service Injection Suite</TeamTitle>
            <TeamCopy>
              Our injection suite is equipped with a state-of-the-art
              fluoroscope, which our doctors can use to deliver both therapeutic
              and diagnositic injections. Therapeutic injections, also known as
              an epidural, use a steroid medication to reduce inflammation
              directly at the source of pain. Diagnostic injections work by
              either testing with steroid for the source of pain, or injecting a
              contrast dye into a suspect disk for evaluation via X-Ray.
            </TeamCopy>
          </TeamHeader>
          <Carousel height={1}>
            {injection.images.map(im => (
              <FacCard key={im.resolutions.src} image={im} />
            ))}
          </Carousel>
        </TeamSection>

        <TeamSection color="#EEF2F7">
          <TeamHeader>
            <TeamTitle>Physical Therapy Clinic</TeamTitle>
            <TeamCopy>
              Physical Therapy is an essential part of most treatment plans. It
              is a powerful weapon for the prevention of spine conditions and
              pain. We designed our physical therapy clinic with all the
              equipment and space you need to receive the right treatment and
              get you headed towards recovery.
            </TeamCopy>
          </TeamHeader>
          <Carousel height={1}>
            {pt.images.map(im => (
              <FacCard key={im.resolutions.src} image={im} />
            ))}
          </Carousel>
        </TeamSection>
        <LearnMore />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const TeamCopy = styled.p`
  && {
    max-width: 580px;
    margin: auto;
  }
`;

const TeamTitle = styled.h1`
  && {
    text-align: center;
    margin-top: 0;
  }
`;

const TeamHeader = styled.div`
  && {
  }
`;

const TopTeamSection = styled(Section)`
  && {
    padding-top: 80px;
    ${query(MEDIUM_BREAKPOINT)`
    padding-top: 290px;
  `} ${query(865)`
  `};
    ${query(1000)`
  `};
  }
`;

const TeamSection = styled(Section)`
  && {
    margin-top: 40px;
  }
`;

export const pageQuery = graphql`
  fragment Facility on ContentfulFacility {
    shortDescription
    name
    images {
      sizes(maxWidth: 500) {
        ...GatsbyContentfulSizes
      }
      resolutions(width: 335, quality: 70) {
        src
        srcSet
      }
    }
  }

  query facilityPhotos {
    mainImage: file(relativePath: { eq: "square/pt.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/injections.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/xray.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    office: contentfulFacility(name: { eq: "Office" }) {
      ...Facility
    }
    pt: contentfulFacility(name: { eq: "Physical Therapy Clinic" }) {
      ...Facility
    }
    mri: contentfulFacility(name: { eq: "Open MRI" }) {
      ...Facility
    }
    xray: contentfulFacility(name: { eq: "X-Ray" }) {
      ...Facility
    }
    injection: contentfulFacility(name: { eq: "Injection Suite" }) {
      ...Facility
    }
  }
`;
