// @flow
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
// @flow
import React from 'react';
import styled from 'styled-components';
import { query } from '../style';
import { blue } from '../style/colors';
import type { ImageSharp } from '../types';

export default ({
  name,
  subheading,
  image,
  className,
  description,
  src = '',
  to = '/team',
}: {
  name?: string,
  image?: ImageSharp,
  src?: string,
  description?: string,
  className?: string,
  subheading?: string,
  to?: string,
}) => {
  return (
    <StyledCard
      style={{ margin: 20, maxWidth: 600, width: '90vw' }}
      className={className}
    >
      {image && image.sizes ? (
        <CardMedia sizes={image && image.sizes ? image.sizes : null} />
      ) : (
        <CardMedia
          src={image ? image.resolutions.base64 : src}
          srcSet={image ? image.resolutions.srcSet : ''}
          style={{ width: '100%', display: 'block' }}
        />
      )}
      {name && (
        <CardContent>
          {name && <Name>{name}</Name>}
          {subheading && (
            <Subheading variant="subheading">{subheading}</Subheading>
          )}
          <Research>{description}</Research>
        </CardContent>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  && {
    ${query(440)`
    width: 500px;
  `};
    overflow: hidden;
  }
`;

const CardMedia = styled(Img)`
  && {
  }
`;
const CardMediaOrig = styled.img`
  && {
  }
`;

const Name = styled.h3`
  && {
    margin-top: 14px;
    margin-bottom: 5px;
  }
`;

const Subheading = styled(Typography)`
  && {
    color: ${blue[300]};
    font-size: 15px;
    font-weight: 300;
  }
`;

const Research = styled.p`
  && {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;
